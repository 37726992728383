// .cursos {
//   --alpha5: hsla(0, 0%, 93%, 1);
//   --alpha3: hsla(0, 0%, 87%, 1);
//   --alpha2: hsla(0, 0%, 81%, 1);
//   --alpha1: hsla(0, 0%, 75%, 1);
//   --beta9: hsla(281, 54%, 90%, 1);
//   --beta5: hsla(281, 54%, 50%, 1);
//   --beta3: hsla(281, 54%, 30%, 1);
//   --beta2: hsla(281, 54%, 20%, 1);
// }
.teatro-terapia {
  --alpha5: hsla(281, 54%, 60%, 1);
  --alpha3: hsla(281, 54%, 40%, 1);
  --alpha2: hsla(281, 54%, 25%, 1);
  --alpha1: hsla(281, 54%, 15%, 1);
  --beta9: hsla(39, 100%, 90%, 1);
  --beta5: hsla(39, 100%, 50%, 1);
  --beta3: hsla(39, 100%, 30%, 1);
  --beta2: hsla(39, 100%, 20%, 1);

  --background-image: url('/img/transparent-white.svg');
  --text-color: hsla(0, 0%, 90%, 1);
}
// .expresartes {
//   --alpha5: hsla(210, 100%, 60%, 1);
//   --alpha3: hsla(210, 100%, 40%, 1);
//   --alpha2: hsla(210, 100%, 25%, 1);
//   --alpha1: hsla(210, 100%, 20%, 1);
//   --beta9: rgba(255, 255, 255, .75);
//   --beta5: white;
//   --beta3: white;
//   --beta2: white;

//   --background-image: url('/img/transparent-white.svg');
//   --text-color: orange;
// }
.expresarte {
  --alpha5: hsla(33, 35%, 60%, 1);
  --alpha3: hsla(33, 35%, 50%, 1);
  --alpha2: hsla(33, 35%, 40%, 1);
  --alpha1: hsla(33, 35%, 30%, 1);
  --beta9: rgba(255, 255, 255, .75);
  --beta5: white;
  --beta3: white;
  --beta2: white;

  --background-image: url('/img/transparent-white.svg');
  --text-color: hsla(17, 83%, 15%, 1);
  // --text-color: hsla(39, 100%, 50%, 1);
}
.innovando-experiencias {
  --alpha5: hsla(0, 0%, 90%, 1);
  --alpha3: hsla(0, 0%, 70%, 1);
  --alpha2: hsla(0, 0%, 60%, 1);
  --alpha1: hsla(0, 0%, 50%, 1);
  --beta9: hsla(281, 54%, 51%, .25);
  --beta5: hsla(281, 54%, 31%, 1);
  --beta3: hsla(281, 54%, 21%, 1);
  --beta2: hsla(281, 54%, 16%, 1);

  --background-image: url('/img/transparent-black.svg');
  --text-color: black;
}
.habilidades-sociales {
  --alpha5: hsla(45, 100%, 60%, 1);
  --alpha3: hsla(45, 100%, 50%, 1);
  --alpha2: hsla(45, 100%, 40%, 1);
  --alpha1: hsla(45, 100%, 35%, 1);
  --beta9: rgba(0, 0, 0, .2);
  --beta5: black;
  --beta3: black;
  --beta2: black;

  --background-image: url('/img/transparent-black.svg');
  --text-color: hsla(281, 54%, 25%, 1);
}
@font-face {
	font-family: "Euphori";
   src: url("/fonts/euphori.eot?#iefix");
   src: url("/fonts/euphori.eot?#iefix") format("eot"),url("/fonts/euphori.woff2") format("woff2"),url("/fonts/euphori.woff") format("woff"),url("/fonts/euphori.ttf") format("truetype");
}