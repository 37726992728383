.teatro-terapia {
  --alpha5: hsla(281, 54%, 60%, 1);
  --alpha3: hsla(281, 54%, 40%, 1);
  --alpha2: hsla(281, 54%, 25%, 1);
  --alpha1: hsla(281, 54%, 15%, 1);
  --beta9: hsla(39, 100%, 90%, 1);
  --beta5: hsla(39, 100%, 50%, 1);
  --beta3: hsla(39, 100%, 30%, 1);
  --beta2: hsla(39, 100%, 20%, 1);
  --background-image: url("/img/transparent-white.svg");
  --text-color: hsla(0, 0%, 90%, 1);
}

.expresarte {
  --alpha5: hsla(33, 35%, 60%, 1);
  --alpha3: hsla(33, 35%, 50%, 1);
  --alpha2: hsla(33, 35%, 40%, 1);
  --alpha1: hsla(33, 35%, 30%, 1);
  --beta9: rgba(255, 255, 255, .75);
  --beta5: white;
  --beta3: white;
  --beta2: white;
  --background-image: url("/img/transparent-white.svg");
  --text-color: hsla(17, 83%, 15%, 1);
}

.innovando-experiencias {
  --alpha5: hsla(0, 0%, 90%, 1);
  --alpha3: hsla(0, 0%, 70%, 1);
  --alpha2: hsla(0, 0%, 60%, 1);
  --alpha1: hsla(0, 0%, 50%, 1);
  --beta9: hsla(281, 54%, 51%, .25);
  --beta5: hsla(281, 54%, 31%, 1);
  --beta3: hsla(281, 54%, 21%, 1);
  --beta2: hsla(281, 54%, 16%, 1);
  --background-image: url("/img/transparent-black.svg");
  --text-color: black;
}

.habilidades-sociales {
  --alpha5: hsla(45, 100%, 60%, 1);
  --alpha3: hsla(45, 100%, 50%, 1);
  --alpha2: hsla(45, 100%, 40%, 1);
  --alpha1: hsla(45, 100%, 35%, 1);
  --beta9: rgba(0, 0, 0, .2);
  --beta5: black;
  --beta3: black;
  --beta2: black;
  --background-image: url("/img/transparent-black.svg");
  --text-color: hsla(281, 54%, 25%, 1);
}

@font-face {
  font-family: "Euphori";
  src: url("/fonts/euphori.eot?#iefix");
  src: url("/fonts/euphori.eot?#iefix") format("eot"), url("/fonts/euphori.woff2") format("woff2"), url("/fonts/euphori.woff") format("woff"), url("/fonts/euphori.ttf") format("truetype");
}
.flier {
  width: 100%;
  height: 61.8vw;
  position: relative;
  padding-left: 0.5em;
  line-height: 1;
  font-family: "Arial", sans-serif;
}
.flier em {
  font-size: 1.1em;
  color: var(--beta5);
  font-weight: bold;
  font-style: normal;
}
.flier-container {
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.flier__img {
  position: absolute;
  right: 0;
  height: 100%;
  width: auto;
}
.flier__content {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
}
.flier__heading {
  font-size: calc(28px + 62 * (100vw - 270px) / 650);
  transform: rotate(-7deg);
}
.flier__heading span {
  margin-left: 0.5em;
}
.flier__continuation {
  padding-bottom: 1em;
  font-size: calc(13px + 32 * (100vw - 270px) / 650);
  line-height: 1.2;
}
.flier--mini {
  background-image: var(--background-image), radial-gradient(var(--alpha5), var(--alpha1));
}
@media all and (min-width: 700px) {
  .flier--mini .flier__heading {
    font-size: calc(32px + 0.5vw);
  }
}
@media all and (min-width: 700px) {
  .flier--mini .flier__continuation {
    font-size: calc(12px + 0.5vw);
    font-size: clamp(0px, 2.5vw, 24px);
  }
}

@media all and (min-width: 920px) {
  .flier {
    height: 568.56px;
  }
  .flier__heading {
    font-size: 4em;
  }
  .flier__continuation {
    font-size: 45px;
  }
}