@import 'vars';

.flier {
  em {
    font-size: 1.1em;
    color: var(--beta5);
    font-weight: bold;
    font-style: normal;
  }
  &-container {
    width: 100%;
    margin: 0;
    // padding: 0;
    overflow: hidden;
  }

  width: 100%;
  height: 61.8vw;
  position: relative;
  padding-left: .5em;
  line-height: 1;
  font-family: 'Arial', sans-serif;

  &__img {
    position: absolute;
    right: 0;
    height: 100%;
    width: auto;
  }
  &__content {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);
  }
  &__heading {
    font-size: calc(28px + (90 - 28) * (100vw - 270px) / (920 - 270));
    transform: rotate(-7deg);
    // line-height: 1;
    // margin-left: .5em;

    span {
      margin-left: .5em;
    }
  }
  &__continuation {
    padding-bottom: 1em;
    font-size: calc(13px + (45 - 13) * (100vw - 270px) / (920 - 270));
    line-height: 1.2;
  }

  &--mini {
    background-image: var(--background-image), radial-gradient(var(--alpha5), var(--alpha1));
    // max-height: 30vw;
    .flier__heading {
      @media all and (min-width: 700px) {
        font-size: calc(32px + 0.5vw);
      }
    }
    .flier__continuation {
      @media all and (min-width: 700px) {
        font-size: calc(12px + 0.5vw);
        font-size: clamp(0px, 2.5vw, 24px);
      }
    }
  }
}

@media all and (min-width: 920px) {
  .flier {
    height: calc(.618 * 920px);
    &-container {
    }

    &__img {
    }

    &__content {
    }

    &__heading {
      // font-size: 96px;
      // font-size: reset;
      font-size: 4em;
    }

    &__continuation {
      font-size: 45px;
    }
  }
}
